import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getTermNameFrom } from "./universities";
export default function Unsubscribe() {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [isFlowComplete, setIsFlowComplete] = useState(false)
  const [askForReview, setAskForReview] = useState(false)

  const submitFeedback = async (isEnrolled) => {
    const response = await fetch(
      process.env.REACT_APP_UNSUBSCRIBE_FEEDBACK_FUNCTION_URL,
      {
        method: "POST",
        body: JSON.stringify({
          isEnrolled: isEnrolled,
          unsubscribeReason: feedback,
          registration: {
            email: searchParams.get("email"),
            university: searchParams.get("university") ?? "ucalgary",
            term: searchParams.get("term"),
            subject: searchParams.get("subject"),
            courseNumber: searchParams.get("courseNumber"),
            sections: searchParams.get("sections").replaceAll(",", "#"),
          }
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response)
  }

  const handleSuccesfulEnrollment = async () => {
    await submitFeedback("yes")
    setAskForReview(true)
  }

  const handlUnsuccessfulEnrollment = async () => {
    await submitFeedback("no")
    setIsFlowComplete(true)
  }


  const unsubscribe = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(
        process.env.REACT_APP_UNSUBSCRIBE_FUNCTION_URL,
        {
          method: "POST",
          body: JSON.stringify({
            email: searchParams.get("email"),
            university: searchParams.get("university") ?? "ucalgary",
            term: searchParams.get("term"),
            subject: searchParams.get("subject"),
            courseNumber: searchParams.get("courseNumber"),
            sections: searchParams.get("sections").replaceAll(",", "#"),
            token: searchParams.get("t"),
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok && !response.headers.has("X-Amz-Function-Error")) {
        setIsLoading(false);
        setIsUnsubscribed(true);
      } else {
        throw new Error("Could not unsubscribe");
      }
    } catch (e) {
      setIsLoading(false);
      setHasError(true);
    }
  };

  return (
    <div className="bg-[#FAF7F0] ">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="h-screen flex justify-center items-center mx-auto max-w-4xl ">
          <div className="text-center">
            {isFlowComplete ? ( <>
              <p className="text-lg font-bold text-gray-900 text-5xl">
                Thank you, your feedback helps us improve. We hope to see you again!
              </p> </>) 
              : askForReview ? (
                <>
                  <p className="text-lg font-bold text-gray-900 text-5xl">
                    That's great news!
                  </p>
                  <p className="mt-4 text-gray-700 text-lg">
                  To get your next monitoring registration for <span className="text-[#116A7B] font-semibold">free</span>, like our <a
                      href="https://www.facebook.com/profile.php?id=61570518900198"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline font-semibold"
                    >
                      Facebook page
                    </a> and leave us a review. Just email <span className="font-semibold">opencoursenotifyme@gmail.com</span> with a screenshot of your review to be eligible.          </p>
                </>
              ): isUnsubscribed ? (
              <>
              <p className="text-lg font-bold text-gray-900 text-5xl">
                You've unsubscribed.  Were you able to enrol in the course?
              </p>

               <div className="flex justify-center space-x-4 mt-6">
          <button
            className="px-6 py-2 rounded-md bg-[#116A7B] text-white shadow-sm hover:bg-[#116A7B] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#116A7B]"
            onClick={() => handleSuccesfulEnrollment()}
          >
            Yes
          </button>
          <button
            className="px-6 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
            onClick={() => setShowFeedback(true)}
          >
            No
          </button>
        </div>

        {showFeedback && (
          <div className="mt-8">
            <label
              htmlFor="feedback"
              className="block text-sm font-medium text-gray-700"
            >
              Could you tell us why you're unsubscribing?
            </label>
            <textarea
              id="feedback"
              rows={3}
              className="w-full mt-2 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="E.g. I no longer want to take this course, it was still full, etc."
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
            <button
              className="px-4 py-2 mt-4 rounded-md bg-[#116A7B] text-white shadow-sm hover:bg-[#116A7B] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#116A7B]"
              onClick={() => handlUnsuccessfulEnrollment()}
            >
              Submit Feedback
            </button>
          </div>
        )}
              </>
            ) : (
              <>
                <p className="text-lg font-bold text-gray-900 text-5xl">
                  Are you sure you'd like to permanently stop receiving email
                  notifications when a spot opens up for{" "}
                  {searchParams.get("subject")}{" "}
                  {searchParams.get("courseNumber")}{" "}
                  {searchParams.get("sections").replaceAll(",", " ")}{" "}
                  {getTermNameFrom(
                    searchParams.get("university") ?? "ucalgary",
                    searchParams.get("term")
                  )}
                  ?
                </p>
                <button
                  disabled={isLoading}
                  className="mt-5 text-center block w-full rounded-md bg-[#116A7B] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#116A7B] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#116A7B]"
                  onClick={unsubscribe}
                >
                  {isLoading ? (
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline mr-3 w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      ></path>
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  ) : (
                    <> Confirm</>
                  )}
                </button>
                {hasError && (
                  <p className="mt-3 text-red-400">
                    Something went wrong, please make sure you've visited the
                    correct link from your email and try again. Contact
                    support@opencoursenotify.me if the problem persists.
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
